.UKPI_V4 {
/*
	*******************************************
	Template Name: Allegiant
	Author: Amal k
	Date:10-02-2023
	Template By: Vandalay Designs
	Copyright 2023-2024 Vandalay Business Solution

	* This file contains the styling for the Allegiant, this
	is the file you need to edit to change the look of the
	site.

	This files contents are outlined below >>>>

	*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
	*******************************************
	*/





// 	============================================
// ==== T A B L E   O F   C O N T E N T S =====
// ============================================
// 01 - Sass Variables
// 02 - QUESTIONIRE BODY
// 03 - ANIMATION CLASSES
// 04 - ANIMATION 
// 05 - MEDIA QUERY



/*================================================
01 - Sass Variables
==================================================*/

margin: 0;
//font-family: var(--bs-body-font-family);
font-size: var(--bs-body-font-size);
font-weight: var(--bs-body-font-weight);
line-height: var(--bs-body-line-height);
color: var(--bs-body-color);
text-align: var(--bs-body-text-align);
background-color: var(--bs-body-bg);
-webkit-text-size-adjust: 100%;
-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
$background_color_2: #2f3996;
font-family: "Arial",sans-serif !important;

$color_1: #015882;


/*================================================
02 - QUESTIONIRE BODY 
==================================================*/

body{ 
  font-family: "Arial",sans-serif !important;
  
}

header{ 
  .logo_1{ 
    height: 75px;
  }
}

.SECT{
  .form_part{ 
    width: 80%;
    margin:auto;
    h1{ 
     color: #000000;
     font-size: 41px;

      span{
        color: $color_1 !important;
      }
    }
    p{
      color: #ff0000;
      text-align: center;
      font-size: 14px;
    }
    .arrow_gif{
      text-align: center;
      img{
        height: 20px;
      }
  
    }

    .icon_option,.my-bt{ 
      font-weight: 500 !important;
      background-color: #015882 !important;
      color: #ffffff !important;
      font-size: 24px;
      line-height: 3px;
      text-decoration: none;
      font-style: normal;
      padding-top: 37px !important;
      padding-bottom: 37px !important;
      border-width: nullpx !important;
      border-style: none !important;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      border-bottom-left-radius: 15px;
      text-align: center;
      width: 100%;
      display: block;
      cursor: pointer;
    }
    label input[type=radio] {
      display: none!important;
    }

    .form-control {
      border-radius: 5px 0 0 5px !important;
    }
    .input-group-text {
      border-radius: 0 5px 5px 0;
    }
    .qstn_1{ 
      font-weight: 700;
      font-size: 30px;
      color: #4c4b4b;
      margin-bottom: 20px !important;
    }
    h5{ 
      color: #4c4b4b;
    }
    


    .back_btn{
      font-size: 20px;
      color: #006ebe!important;
      padding: 10px 0;
      cursor: pointer;
    }
  }
}



.progress{ 
  .bg-grn{ 
    background-color: #015882;
  }
}


.footer_text { 
  // font-family: "Poppins",sans-serif!important;
    line-height: 1.5;
    background-color: #dfe5ea;
    padding: 30px 0;
    color: #000!important;
    img {
      height: 80px;
      margin-bottom: 10px;
  }
  .footer_contents{
    margin: auto;
    h6{
      font-size: 14px;
    }
    p {
      font-size: 12px;
    }
  }
  .footer_links{
    padding-left: 5%;
    h6{
      font-size: 14px;
    }
    ul {
      list-style: none;
      display: block;
      padding: 0;
      li {
        padding: 0 5px;
        a {
          color: #000!important;
          text-decoration: underline;
          cursor: pointer;
          font-size: 10px;
        }
      }
    }
  }
}

footer{
  background-color: #cbdae6;
  padding: auto;

  .footer_logo{
    padding: 15px;

    img{
      height: 65px;
    }
  }
}


.error_msg{ 
  color: #fff;
  width: 100%;
  float: left;
  background: red;
  font-size: 13px;
  padding: 0 5px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.modal{ 
  background-color: rgba(0, 0, 0,.9);
  h5{ 
    font-weight: 300;
  }
  .custom-mdal-btn {
    margin: 10px 0!important;
    font-size: 20px!important;
    width: 35%;
    background-color: #015882!important;
    border: 0!important;
    border-bottom: 4px solid #003149!important;
    font-weight: 300;
    .slide-pad{ 
      h2{ 
        font-weight: 100;

      }
    }
}

.bord-b {
  border-bottom: 5px solid #015882;
}
}







// MEDIA QUERY 

@media screen and (max-width: 768px){ 
  header{ 
    .logo_1{ 
      height: 60px;
    }
  }
  .SECT { 


    .text-consent{ 
      font-size: 12px !important;
    }

    .form_part{ 
      width: 100%;
      margin: 0;
      padding-left: 12px; 
      padding-right: 12px;
      h1{ 
        color: #000000 !important;
      }
      .qstn_1 {
        font-size: 18px;
        line-height: 24px;
        margin-top: 15px;
        color: #4c4b4b;
      }
      .icon_option{ 
        font-size: 12px;
        width: 86%;
        margin: auto;
      }
      .form-control {
        border-radius: 5px 0 0 5px !important;
      }
      .input-group-text {
        border-radius: 0 5px 5px 0;
      }
    }
    h1 {
      font-size: 26px!important;
      padding: 10px 15px 8px !important;
    }
    h2 {
      font-size: 20px!important;
    }
   .sm-device-txt{ 
    width: 100%;
    padding: 10px 15px 25px 15px;
   }
  }

  .footer_text{
    .footer_links{
      padding-left: 5px !important;
    }
   }
  

  footer{ 
    .footer_logo{
      padding: 10px;
      img{ 
        height: 55px;
      }
    }
  }
}

@media screen and (max-width: 556px){
  .footer_text{
    .footer_links{
      padding-left: 10px !important;
    }
   }
}

.progress{
  margin: 1.5rem !important;
  height: 20px;
  
}
.progress-bar{
  background-color: #015882;
}
.trustpilot {
  text-align: right;
}
.trustpilot img {
  width: 180px;
}
.rate-num {
font-size: 20px;
    font-weight: 600;
    color: #000;
}
.last-trust-img img{
  margin-top:-18px;
}
}

.custom-modal{
  .pop-text p{
    font-weight: 400 !important;
    font-size: 1.25rem !important;
    font-family: "Arial",sans-serif !important;
  }
  .pop-text{
    padding-bottom: 5px;
  }
  .modal-footer{
    border-top:0;
    padding-bottom: 15px !important;
}
}
.cstm_mdl{
  #analayzeModal h2{
    font-family: "Arial",sans-serif !important;
  }
  #slide62nxt h2{
    font-size: 26px;
  }
}
.UKPI_V4 .SECT .form_part .icon_option.active {
  background-color: #003149 !important;
}




