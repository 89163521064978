@import "node_modules/bootstrap/scss/bootstrap";
@import "bootstrap-icons/font/bootstrap-icons.css";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,300;0,400;0,600;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@import "./assets/Modal/Modal.scss";

.compensation-logo{
    max-width: 200px !important;
  }
  
  @media screen and (max-width: 590px) {
    .compensation-logo{ 
      max-width: 156px !important;
    }
    }
    @media screen and (max-width: 490px) { 
      footer {
        padding-top: 16px; 
        padding-bottom: 16px;
        .compensation-logo{ 
          padding-bottom: 16px;
        }
      
      }
    }

$color_2: #000;
$font_family_1:'Roboto', sans-serif;
$font_family_2:'Oswald', sans-serif;
$font_family_3:'Open Sans', sans-serif;
$font_family_4: 'Titillium Web',sans-serif;
$font_family_5: 'Arial',sans-serif;

body {
font-family: $font_family_1 !important;
font-size: 14px;
line-height: 1.5;
color: $color_2 !important;
background: #f9f9fa;
}