.UKPI_V5 {
  /*
	*******************************************
	Template Name: Allegiant
	Author: Amal k
	Date:10-02-2023
	Template By: Vandalay Designs
	Copyright 2023-2024 Vandalay Business Solution

	* This file contains the styling for the Allegiant, this
	is the file you need to edit to change the look of the
	site.

	This files contents are outlined below >>>>

	*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
	*******************************************
	*/

  // 	============================================
  // ==== T A B L E   O F   C O N T E N T S =====
  // ============================================
  // 01 - Sass Variables
  // 02 - QUESTIONIRE BODY
  // 03 - ANIMATION CLASSES
  // 04 - ANIMATION
  // 05 - MEDIA QUERY

  /*================================================
01 - Sass Variables
==================================================*/

  margin: 0;
  //font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  $background_color_2: #2f3996;
  font-family: "Arial", sans-serif !important;

  $color_1: #015882;

  /*================================================
02 - QUESTIONIRE BODY 
==================================================*/

  body {
    font-family: "Arial", sans-serif !important;
  }

  header {
    .logo_1 {
      height: 75px;
    }
  }

  .active {
    background-color: #002a3e !important;
  }

  .SECT {
    .form_part {
      width: 80%;
      margin: auto;
      h1 {
        color: #000000;
        font-size: 36px;

        span {
          color: $color_1 !important;
        }
      }
      p {
        color: #ff0000;
        text-align: center;
        font-size: 14px;
      }
      .arrow_gif {
        text-align: center;
        img {
          height: 20px;
        }
      }

      .icon_option,
      .my-bt {
        font-weight: 500 !important;
        background-color: #015882;
        color: #ffffff !important;
        font-size: 24px;
        line-height: 1.5;
        text-decoration: none;
        font-style: normal;
        padding-top: 37px !important;
        padding-bottom: 37px !important;
        border-width: nullpx !important;
        border-style: none !important;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        text-align: center;
        width: 100%;
        display: block;
        cursor: pointer;
      }

      .special_button {
        font-weight: 500 !important;
        background-color: #fbb53c;
        color: #ffffff !important;
        font-size: 24px;
        line-height: 3px;
        text-decoration: none;
        font-style: normal;
        padding-top: 37px !important;
        padding-bottom: 37px !important;
        border-width: nullpx !important;
        border-style: none !important;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        text-align: center;
        width: 100%;
        display: block;
        cursor: pointer;
      }

      label input[type="radio"] {
        display: none !important;
      }

      .form-control {
        border-radius: 5px 0 1 5px !important;
      }
      .input-group-text {
        border-radius: 0 5px 5px 0;
      }
      .qstn_1 {
        font-weight: 700;
        font-size: 30px;
        color: #4c4b4b;
        margin-bottom: 20px !important;
      }
      h5 {
        color: #4c4b4b;
      }
      .radioa {
        background: #015882;
        font-size: 16px;
        width: 100%;
        margin-top: 0px;
        cursor: pointer;
        padding: 20px 15px;
        color: #fff;
        font-weight: 600;
      }
      .custom-checkbox:checked + label {
        background: #003149;
      }

      .back_btn {
        font-size: 20px;
        color: #006ebe !important;
        padding: 10px 0;
        cursor: pointer;
        display: block;
      }
    }
  }

  .progress {
    .bg-grn {
      background-color: #015882;
    }
  }

  .footer_text {
    // font-family: "Poppins",sans-serif!important;
    line-height: 1.5;
    background-color: #dfe5ea;
    padding: 30px 0;
    color: #000 !important;
    img {
      height: 80px;
      margin-bottom: 10px;
    }
    .footer_contents {
      margin: auto;
      h6 {
        font-size: 14px;
      }
      p {
        font-size: 12px;
      }
    }
    .footer_links {
      padding-left: 5%;
      h6 {
        font-size: 14px;
      }
      ul {
        list-style: none;
        display: block;
        padding: 0;
        li {
          padding: 0 5px;
          a {
            color: #000 !important;
            text-decoration: underline;
            cursor: pointer;
            font-size: 10px;
          }
        }
      }
    }
  }

  footer {
    background-color: #cbdae6;
    padding: auto;

    .footer_logo {
      padding: 15px;

      img {
        height: 65px;
      }
    }
  }

  .error_msg {
    color: #fff;
    width: 100%;
    float: left;
    background: red;
    font-size: 13px;
    padding: 0 5px;
    border-radius: 4px;
    margin-bottom: 10px;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }

  // MEDIA QUERY

  @media screen and (max-width: 768px) {
    header {
      .logo_1 {
        height: 60px;
      }
    }
    .SECT {
      //  NEW
      .modal {
        // .modal-6n{
        //   .text-6n{

        //     font-size:40px;
        //     width: 80%;
        //     .curly-bracket-cls{
        //       font-weight: bolder;
        //       color: #267bfc;
        //     }
        //     .curly-bkt-txt{
        //       padding-left: 20px;
        //       padding-right: 20px;
        //       font-size: 12px;
        //     }
        //   }
        // }
      }

      // END

      .text-consent {
        font-size: 12px !important;
      }

      .form_part {
        width: 100%;
        margin: 0;
        padding-left: 12px;
        padding-right: 12px;
        h1 {
          color: #000000 !important;
        }
        .qstn_1 {
          font-size: 18px;
          line-height: 24px;
          color: #4c4b4b;
        }
        .icon_option {
          font-size: 12px;
          width: 86%;
          margin: auto;
        }
        .form-control {
          border-radius: 5px 0 0 5px !important;
        }
        .input-group-text {
          border-radius: 0 5px 5px 0;
        }

        .special_button {
          width: 86%;
          margin: 25px auto 0;
        }
      }
      h1 {
        font-size: 26px !important;
        padding: 10px 15px 8px !important;
      }
      h2 {
        font-size: 20px !important;
      }
      .sm-device-txt {
        width: 100%;
        padding: 10px 15px 0px 15px;
      }
    }

    .footer_text {
      .footer_links {
        padding-left: 5px !important;
      }
    }

    footer {
      .footer_logo {
        padding: 10px;
        img {
          height: 55px;
        }
      }
    }
  }

  @media screen and (max-width: 556px) {
    .footer_text {
      .footer_links {
        padding-left: 10px !important;
      }
    }

    .cstm_mdl {
      .good-news {
        h4,
        h2 {
          font-size: 13px !important;
        }
      }
    }
  }

  .progress {
    margin: 15px !important;
    height: 20px;
  }
  .progress-bar {
    background-color: #015882;
  }
  .trustpilot {
    text-align: right;
  }
  .trustpilot img {
    width: 180px;
  }
  .rate-num {
    font-size: 20px;
    font-weight: 600;
    color: #000;
  }
  .last-trust-img img {
    margin-top: -18px;
  }
}

.custom-modal {
  .pop-text p {
    font-weight: 400 !important;
    font-size: 1.25rem !important;
    font-family: "Arial", sans-serif !important;
  }
  .pop-text {
    padding-bottom: 5px;
  }
  .modal-footer {
    border-top: 0;
    padding-bottom: 15px !important;
  }
}
.cstm_mdl {
  #analayzeModal h2 {
    font-family: "Arial", sans-serif !important;
  }
  #slide62nxt h2 {
    font-size: 26px;
  }

  .good-news {
    align-items: center;
    border: 4px solid #96a3ff;
    appearance: none;
    background-image: radial-gradient(
      100% 100% at 100% 0,
      #5adaff 0,
      #5468ff 100%
    );
    box-sizing: border-box;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;

    position: relative;
    text-align: left;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
  }
}
.UKPI_V4 .SECT .form_part .icon_option.active {
  background-color: #003149 !important;
}

.bgno {
  //NEW //

  background-color: rgba(0, 0, 0, 0.9);
  h5 {
    font-weight: 300;
  }
  .custom-mdal-btn {
    margin: 10px 0 !important;
    font-size: 20px !important;
    width: 35%;
    background-color: #015882 !important;
    border: 0 !important;
    border-bottom: 4px solid #003149 !important;
    font-weight: 300;
    .slide-pad {
      h2 {
        font-weight: 100;
      }
    }
  }

  .bord-b {
    border-bottom: 5px solid #015882;
  }

  .modal-6n {
    background: #fff;
    border-radius: 8px;
    .loader {
      border-radius: 20px;
      margin: auto;
      --background: linear-gradient(135deg, #23c4f8, #275efe);
      --shadow: rgba(39, 94, 254, 0.28);
      --text: #6c7486;
      --page: rgba(255, 255, 255, 0.36);
      --page-fold: rgba(255, 255, 255, 0.52);
      --duration: 3s;
      width: 200px;
      height: 140px;
      position: relative;

      &::before,
      &::after {
        --r: -6deg;
        content: "";
        position: absolute;
        bottom: 8px;
        width: 120px;
        top: 80%;
        box-shadow: 0 16px 12px var(--shadow);
        transform: rotate(var(--r));
      }
      &::before {
        left: 4px;
      }
      &::after {
        --r: 6deg;
        right: 4px;
      }
      div {
        width: 100%;
        height: 100%;
        border-radius: 13px;
        position: relative;
        z-index: 1;
        perspective: 600px;
        box-shadow: 0 4px 6px var(--shadow);
        background-image: var(--background);

        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          position: relative;

          li {
            --r: 180deg;
            --o: 0;
            --c: var(--page);
            position: absolute;
            top: 10px;
            left: 10px;
            transform-origin: 100% 50%;
            color: var(--c);
            opacity: var(--o);
            transform: rotateY(var(--r));
            -webkit-animation: var(--duration) ease infinite;
            animation: var(--duration) ease infinite;
            &:nth-child(2) {
              --c: var(--page-fold);
              -webkit-animation-name: page-2;
              animation-name: page-2;
            }
            &:nth-child(3) {
              --c: var(--page-fold);
              -webkit-animation-name: page-3;
              animation-name: page-3;
            }
            &:nth-child(4) {
              --c: var(--page-fold);
              -webkit-animation-name: page-4;
              animation-name: page-4;
            }
            &:nth-child(5) {
              --c: var(--page-fold);
              -webkit-animation-name: page-5;
              animation-name: page-5;
            }

            svg {
              width: 90px;
              height: 120px;
              display: block;
            }

            &:first-child {
              --r: 0deg;
              --o: 1;
            }

            &:last-child {
              --o: 1;
            }
          }
        }
      }

      span {
        display: block;
        left: 0;
        right: 0;
        top: 100%;
        margin-top: 20px;
        text-align: center;
        color: var(--text);
      }
    }

    .text-6n {
      color: black;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      margin-top: 60px;
      font-size: 90px;
      width: 90%;
      .curly-bracket-cls {
        font-weight: bolder;
        color: #267bfc;
      }
      .curly-bkt-txt {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 16px;
      }
    }
  }

  @-webkit-keyframes page-2 {
    0% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    35%,
    100% {
      opacity: 0;
    }
    50%,
    100% {
      transform: rotateY(0deg);
    }
  }

  @keyframes page-2 {
    0% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    35%,
    100% {
      opacity: 0;
    }
    50%,
    100% {
      transform: rotateY(0deg);
    }
  }
  @-webkit-keyframes page-3 {
    15% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    50%,
    100% {
      opacity: 0;
    }
    65%,
    100% {
      transform: rotateY(0deg);
    }
  }
  @keyframes page-3 {
    15% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    50%,
    100% {
      opacity: 0;
    }
    65%,
    100% {
      transform: rotateY(0deg);
    }
  }
  @-webkit-keyframes page-4 {
    30% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    65%,
    100% {
      opacity: 0;
    }
    80%,
    100% {
      transform: rotateY(0deg);
    }
  }
  @keyframes page-4 {
    30% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    65%,
    100% {
      opacity: 0;
    }
    80%,
    100% {
      transform: rotateY(0deg);
    }
  }
  @-webkit-keyframes page-5 {
    45% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    65% {
      opacity: 1;
    }
    80%,
    100% {
      opacity: 0;
    }
    95%,
    100% {
      transform: rotateY(0deg);
    }
  }
  @keyframes page-5 {
    45% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    65% {
      opacity: 1;
    }
    80%,
    100% {
      opacity: 0;
    }
    95%,
    100% {
      transform: rotateY(0deg);
    }
  }

  //End
}
@media screen and (max-width: 768px) {
  h4#slide62nxt {
    font-size: 14px;
  }

  .bgno {
    .modal-6n {
      .text-6n {
        width: 100% !important;
        .curly-bracket-cls {
          font-weight: bolder;
          color: #267bfc;
        }
        .curly-bkt-txt {
          padding-left: 10px !important;
          padding-right: 10px !important;
          font-size: 14px !important;
        }
      }
    }
  }
}
