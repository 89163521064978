
.page_centercont{
  font-family: "Roboto",sans-serif !important;
    font-size: 1rem;
    line-height: 1rem;
    color: #000;
    background-color: #fff;
/*
*******************************************
Template Name: The Financial Guru
Author: ABC
Date: 09-11-2022
Template By: Vandalay Designs
Copyright 2020-2022 Vandalay Business Solution

* This file contains the styling for laonclaims.co.uk, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************

============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - RESET
04 - BODY 


============================================
============================================
*/

/*================================================
01 - Sass Variables
==================================================*/

$color_1: #000;
$color_2: rgb(61, 61, 61);
$color_3: rgb(255, 255, 255);
 
$font_family_1: 'Roboto', sans-serif;
 
// ======border===========
.border {
 // border: 1px solid $color_3;
}
 
/*================================================
03 - RESET
==================================================*/
// * {
//   box-sizing: border-box;
//   padding: 0;
//   margin: 0;
//   outline: 0;
//   text-decoration: none;
// }

p {
  font-size: 16px;
  line-height: 22px;
}
a {
  cursor: pointer;
  text-decoration: none;
  // line-height: 20px;
}
ul {
  padding: 0;
  margin: 0;
  li {
    list-style-type: none;
  }
}



/*================================================
04 - BODY STYLES
==================================================*/

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  font-size: 100%;
}
// body {
//   font-family: $font_family_1 !important;
//   font-size: 1rem !important;
//   line-height: 1rem !important;
//   color: $color_1 !important;

// }

/*================================================
END - BODY STYLES
==================================================*/


.under_line {
  text-decoration: underline;
}
 

 
/*================================================
02 - MAIN CONTENTS
==================================================*/
//  .page_centercont {
  
 
  .home-main {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
   height: 100vh;
   .advert {
    position: absolute;
    top: 0;
    z-index: 5;
   }
    h1 {
      font-size: 38px;
      width: 80%;
      margin: 0 auto;
      font-weight: 600;
    }
    h6 {
      color: $color_2;
      font-size:20px;
      width: 70%;
      margin: 0 auto;
    }
    .btn_slide {
      background: green;
      color: $color_3;
      font-weight: bold;
      padding: 8px 20px;
      font-size: 24px;
      &:hover,&:focus,&:active {
        background: rgb(10, 163, 10);
      }
    }
     
  }

  footer {
    background: #eee;
    .ft_menu {
      li {
        display: inline-block;
        border-right: 1px solid rgb(184, 184, 184);
        line-height: 5px;
        &:last-child {
          border-right: 0;
        }
        &:first-child {
          a {
            padding-left: 0;
          }
        }
        a {
          color: $color_1;
          display: block;
          padding: 2px 10px;
        }
      }
    }
  }
//  }





/*================================================
05 - MEADIA QUERY
==================================================*/

$break-max-tab-lg: 980px;
$break-max-tab: 768px;
@media (max-width: $break-max-tab-lg) {
 


}
@media (max-width: $break-max-tab) {
 
  .page_centercont{
    .home-main {
      h1 {font-size: 26px;}
      h4 {font-size: 18px;}
      h6 {font-size: 14px;
        line-height: 20px;}
        .btn_slide {font-size: 16px;}
    }
  }
 
}
}