/*
*******************************************
Template Name: Claimant
Author: Nikhil
Date: 26-07-2022
Template By: Vandalay Designs
Copyright 2021-2022 Vandalay Business Solution

* This file contains the styling for the Claimant, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************

*/

$font_family_1: 'Roboto', sans-serif;


/*================================================
08 - PRIVACY
==================================================*/


.privacy{
    padding: 40px 0px;
    font-family: $font_family_1;
    .directul{
        padding-left: 18px;
    }

    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }
      
    td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }

    .ml-3{
        margin-left: 25px;
    }

    .ml-5{
        margin-left: 45px;
    }
    .mbb{
        background-color: #fff !important;
    color: #000 !important;
    }
}
